.wrapper-container {
  position: absolute;
  inset: 0;

  nav > ol {
    display: flex;
    align-items: center;
    list-style: none;
    text-transform: capitalize;
    li {
      margin: 0px 4px;

      &:after {
        color: rgba(255, 255, 255, 0.6);
        content: "/";
        display: inline-block;
        margin: 0px 4px;
      }

      &:last-child:after {
        content: "";
      }
    }

    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .brActive > * {
    color: rgba(255, 255, 255, 1);
  }
}
