.wrapper-container {
  .tgl-skewed {
    background-color: red;
    &{
      .tgl-btn {
        overflow: hidden;
        transform: skew(-10deg);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: all 0.2s ease;
        font-family: sans-serif;
        background: #888;
        &:after {
          transform: skew(10deg);
          display: inline-block;
          transition: all 0.2s ease;
          width: 100%;
          text-align: center;
          position: absolute;
          line-height: 2em;
          font-weight: bold;
          color: #fff;
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
          left: 100%;
          content: attr(data-tg-on);
        }
        &:before {
          transform: skew(10deg);
          display: inline-block;
          transition: all 0.2s ease;
          width: 100%;
          text-align: center;
          position: absolute;
          line-height: 2em;
          font-weight: bold;
          color: #fff;
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
          left: 0;
          content: attr(data-tg-off);
        }
        &:active {
          background: #888;
          &:before {
            left: -10%;
          }
        }
      }
    }
    &:checked {
      + {
        .tgl-btn {
          background: #86d993;
          &:before {
            left: -100%;
          }
          &:after {
            left: 0;
          }
          &:active {
            &:after {
              left: 10%;
            }
          }
        }
      }
    }
  }
}
