body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #08080a;
  font-size: 16px;
  background-color: #ffffff;
  width: "100%";
}

a {
  cursor: pointer;
}
.wallet-adapter-modal {
  z-index: 1300 !important ;
}

.css-1ul87gc-MuiTableCell-root {
  font-size: 16px !important;
}
.css-15v2iz-MuiTableCell-root {
  font-size: 16px !important;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */

*,
*::before,
*::after {
  box-sizing: inherit;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,.3);
  border-radius: 2px;
}
/* Handle */

::-webkit-scrollbar-thumb {
  background: transparent;
  border: 0.2px solid rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}
/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #11244b;
  cursor: pointer;
}

.console-underscore {
  display:inline-block;
 position:relative;
 left:10px;
}
// .wrapper-container {
//   background: url("/images/img-bg-aboutus.png") no-repeat center center;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   -o-background-size: cover;
//   background-size: cover;
// }